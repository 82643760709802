import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from './translations/en.json';
import ptTranslation from './translations/pt.json';
import deTranslation from './translations/de.json';
import esTranslation from './translations/es.json';
import frTranslation from './translations/fr.json';
import dkTranslation from './translations/dk.json';
import itTranslation from './translations/it.json';
import huTranslation from './translations/hu.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            pt: { translation: ptTranslation },
            de: { translation: deTranslation },
            es: { translation: esTranslation },
            fr: { translation: frTranslation },
            dk: { translation: dkTranslation },
            it: { translation: itTranslation },
            hu: { translation: huTranslation }
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;