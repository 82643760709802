import { Link } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'
import { MdWifi } from 'react-icons/md'
import { IoMdImage } from 'react-icons/io'
import { HiMusicNote } from 'react-icons/hi'
import React, { useMemo, useState } from 'react'
import { BsFillChatRightTextFill, BsLink45Deg } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import {
    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS,
    LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS
} from '../../helpers/image-translations'
import {
    GUIDES_NAVIGATION_TRANSLATIONS
} from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import useGenerateUrl from '../../hooks/useGenerateUrl'
import { useViewport } from '../../hooks/useViewport'
import i18n from '../../i18n/config'

import Testimonials from './Testimonials'
import FaqSection from './FaqSection'

import homeGradientVideoSvg from '../../assets/images/home-gradient-video.svg'
import homeGradientImageSvg from '../../assets/images/home-gradient-image.svg'
import pdfIconDefaultSvg from '../../assets/images/home-pdf-icon-default.svg'
import homeGradientTextSvg from '../../assets/images/home-gradient-text.svg'
import homeGradientWifiSvg from '../../assets/images/home-gradient-wifi.svg'
import pdfIconActiveSvg from '../../assets/images/home-pdf-icon-active.svg'
import homeGradientUrlSvg from '../../assets/images/home-gradient-url.svg'
import homeGradientPdfSvg from '../../assets/images/home-gradient-pdf.svg'
import homeGradientMp3Svg from '../../assets/images/home-gradient-mp3.svg'
import homeDownloadSvg from '../../assets/images/home-download.svg'
import homeQrCodeSvg from '../../assets/images/home-qr-code.svg'
import homeStatsSvg from '../../assets/images/home-stats.svg'
import homeImageSvg from '../../assets/images/home-image.png'
import gradientQrSvg from '../../assets/icons/gradientqr.svg'
import homeLinkSvg from '../../assets/images/home-link.png'
import homePdfSvg from '../../assets/images/home-pdf.png'
import homeMp3Svg from '../../assets/images/home-mp3.png'
import newLogo from '../../assets/icons/newlogo.svg'

const NewHome = () => {
    const [selectedType, setSelectedType] = useState('URL')

    const { isMobile } = useViewport()
    const { t } = useTranslation()

    const navigate = useCustomNavigate()
    const generateUrl = useGenerateUrl()

    const handleNavigateToCreation = () => {
        navigate('/generate-code')
    }

    const handleLogin = () => {
        navigate('/sign-in')
    }

    const handleSignUp = () => {
        navigate('/sign-up?upgrade=true')
        window.gtag('event', 'land_on_sign_up_page', {
            type: 'sign_up_button_click',
        })
    }

    const footerLogoClick = () => {
        window.scrollTo(0, 0)
    }

    const handleNavigateToGuides = () => {
        window.location.href = `${window.location.origin}${GUIDES_NAVIGATION_TRANSLATIONS[i18n.language] ?? '/guides'}`
    }

    const previewAbout = useMemo(() => {
        switch (selectedType) {
            case 'URL': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientUrlSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>{t("websiteUrl")}</h5>
                        <p className='discover-section-preview-content-description'>{t("guideYourAudienceToAWebsite")}</p>
                    </div>
                )
            }
            case 'Text': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientTextSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>{t("text")}</h5>
                        <p className='discover-section-preview-content-description'>{t("aConciseWayToShareMessages")}</p>
                    </div>
                )
            }
            case 'Wi-Fi': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientWifiSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>Wi-Fi</h5>
                        <p className='discover-section-preview-content-description'>{t("effortlessConnectivityShareWifi")}</p>
                    </div>
                )
            }
            case 'PDF': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientPdfSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>PDF</h5>
                        <p className='discover-section-preview-content-description'>{t("shareDocumentsSwiftly")}</p>
                    </div>
                )
            }
            case 'Video': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientVideoSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>{t("video")}</h5>
                        <p className='discover-section-preview-content-description'>{t("engageYourAudienceWithACompellingVideo")}</p>
                    </div>
                )
            }
            case 'MP3': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientMp3Svg} alt="" />
                        <h5 className='discover-section-preview-content-title'>MP3</h5>
                        <p className='discover-section-preview-content-description'>{t("deliverYourFavoriteTunes")}</p>
                    </div>
                )
            }
            case 'Image': {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientImageSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>{t("image")}</h5>
                        <p className='discover-section-preview-content-description'>{t("shareCaptivatingImagesStraightToUser")}</p>
                    </div>
                )
            }
            default: {
                return (
                    <div className='discover-section-codes-preview-about-content'>
                        <img className='discover-section-preview-content-icon' src={homeGradientUrlSvg} alt="" />
                        <h5 className='discover-section-preview-content-title'>{t("websiteUrl")}</h5>
                        <p className='discover-section-preview-content-description'>{t("guideYourAudienceToAWebsite")}</p>
                    </div>
                )
            }
        }
    }, [selectedType, t])

    return (
        <main className='new-home-landing'>
            <section className='new-home-introducing-section'>
                <div className='introducing-section-information'>
                    <h2 className='introducing-section-heading'>
                        {t("generatePowerful")} <br /> {t("dynamicQRCodes")}
                    </h2>
                    <p className='introducing-section-description'>
                        {t("effortlesslyCreateCustomize")} {!isMobile && <br />} {t("codesWithDot")}
                    </p>
                    <button onClick={handleNavigateToCreation} className='introducing-section-create-button'>
                        {t("createQRCode")}
                    </button>
                </div>
                <div className='introducing-section-image-mobile'>
                    <img
                        src={
                            LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.mobile
                            ?? LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS['en'].mobile
                        }
                        alt=""
                    />
                </div>
                <div className='introducing-section-image'>
                    <img
                        style={{ maxWidth: '600px' }}
                        src={
                            LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.desktop
                            ?? LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS['en'].desktop
                        }
                        alt=""
                    />
                    <img className='introducing-section-image-image' src={homeImageSvg} alt="" />
                    <img className='introducing-section-image-link' src={homeLinkSvg} alt="" />
                    <img className='introducing-section-image-pdf' src={homePdfSvg} alt="" />
                    <img className='introducing-section-image-mp3' src={homeMp3Svg} alt="" />
                    <img
                        className='introducing-section-image-notification'
                        src={
                            LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.notification
                            ?? LANDING_PAGE_INTRODUCING_SECTION_IMAGES_TRANSLATIONS['en'].notification
                        }
                        alt=""
                    />
                </div>
            </section>
            <section className='new-home-abilities-section'>
                <div className='new-home-abilities-section-items-wrapper'>
                    <div className='abilities-section-block'>
                        <img className='abilities-section-block-icon' src={homeQrCodeSvg} alt="" />
                        <h5 className='abilities-section-block-title'>{t("createCustomQRCodes")}</h5>
                        <p className='abilities-section-block-description'>{t("chooseFromVarietyOfDynamicTypes")}</p>
                    </div>
                    <div className='abilities-section-block'>
                        <img className='abilities-section-block-icon' src={homeDownloadSvg} alt="" />
                        <h5 className='abilities-section-block-title'>{t("downloadShareAndEdit")}</h5>
                        <p className='abilities-section-block-description'>{t("instantlyDownloadShareAndEdit")}</p>
                    </div>
                    <div className='abilities-section-block'>
                        <img className='abilities-section-block-icon' src={homeStatsSvg} alt="" />
                        <h5 className='abilities-section-block-title'>{t("trackAVarietyOfAnalytics")}</h5>
                        <p className='abilities-section-block-description'>{t("keepTabsOnYOurQRCodeScans")}</p>
                    </div>
                </div>
            </section>
            <section className='new-home-discover-section'>
                <h3 className='discover-section-heading'>{t("discoverDifferentQr")} {isMobile && <br />} {t("codeTypes")}</h3>
                <p className='discover-section-description'>{t("exploreTheDiverseRangeOfQRCodeTypes")}</p>
                <div className='discover-section-codes-preview-block'>
                    <div className='discover-section-codes-preview-list'>
                        <div onClick={() => setSelectedType('URL')} className={selectedType === 'URL' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <BsLink45Deg size={25} fill={selectedType === 'URL' ? '#FFF' : '#E2E8F0'} /> URL
                        </div>
                        <div onClick={() => setSelectedType('Text')} className={selectedType === 'Text' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <BsFillChatRightTextFill size={21} fill={selectedType === 'Text' ? '#FFF' : '#E2E8F0'} /> {t("text")}
                        </div>
                        <div onClick={() => setSelectedType('Wi-Fi')} className={selectedType === 'Wi-Fi' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <MdWifi size={24} fill={selectedType === 'Wi-Fi' ? '#FFF' : '#E2E8F0'} /> Wi-Fi
                        </div>
                        <div onClick={() => setSelectedType('PDF')} className={selectedType === 'PDF' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <img className='discover-section-code-icon-img' src={selectedType === 'PDF' ? pdfIconActiveSvg : pdfIconDefaultSvg} alt="" /> PDF
                        </div>
                        <div onClick={() => setSelectedType('Video')} className={selectedType === 'Video' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <FaPlay size={22} fill={selectedType === 'Video' ? '#FFF' : '#E2E8F0'} /> {t("video")}
                        </div>
                        <div onClick={() => setSelectedType('MP3')} className={selectedType === 'MP3' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <HiMusicNote size={24} fill={selectedType === 'MP3' ? '#FFF' : '#E2E8F0'} /> MP3
                        </div>
                        <div onClick={() => setSelectedType('Image')} className={selectedType === 'Image' ? 'discover-section-code-item-active' : 'discover-section-code-item-default'}>
                            <IoMdImage size={24} fill={selectedType === 'Image' ? '#FFF' : '#E2E8F0'} /> {t("image")}
                        </div>
                    </div>
                    <div className='discover-section-codes-preview'>
                        <button onClick={handleNavigateToCreation} className='introducing-section-create-button mt-0 hidden mobile:block'>
                            {t("createQRCode")}
                        </button>
                        <div className='discover-section-codes-preview-image'>
                            <img
                                className={selectedType === 'URL' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.url
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].url
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'Text' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.text
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].text
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'Wi-Fi' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.['wi-fi']
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en']['wi-fi']
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'PDF' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.pdf
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].pdf
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'Video' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.video
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].video
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'MP3' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.mp3
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].mp3
                                }
                                alt=""
                            />
                            <img
                                className={selectedType === 'Image' ? 'discover-section-codes-preview-picture' : 'hidden'}
                                src={
                                    LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS[i18n.language]?.image
                                    ?? LANDING_PAGE_DISCOVER_SECTION_IMAGES_TRANSLATIONS['en'].image
                                }
                                alt=""
                            />
                        </div>
                        <div className='discover-section-codes-preview-about'>
                            {previewAbout}
                            <button onClick={handleNavigateToCreation} className='introducing-section-create-button mt-6 mobile:hidden'>
                                {t("createQRCode")}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='new-home-empower-section'>
                <div className='empower-section-gradient-wrapper'>
                    <div className='empower-section-wrapper'>
                        <div className='convert-code-icon-block'>
                            <div className='home-convert-code-qr-image-gradient-wrapper'>
                                <div className='convert-code-qr-image-wrapper z-10'>
                                    <img className='convert-code-qr-image' src={gradientQrSvg} alt="" />
                                </div>
                                <div className='home-convert-code-graient'></div>
                            </div>
                            <h5 className='convert-code-qr-text empower-block-title-text'>
                                {t("empowerYourBrandWithDynamicQRCodes")}
                            </h5>
                            <p className='empower-section-description'>
                                {t("harnessTheFutureOfQRTechnology")}
                            </p>
                            <button onClick={handleNavigateToCreation} className='introducing-section-create-button'>
                                {t("createQRCode")}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <FaqSection />
            <footer className='new-home-footer'>
                <div className='new-home-footer-wrapper'>
                    <div className='footer-main-info'>
                        <div onClick={footerLogoClick} className='flex items-start cursor-pointer me-2 md:me-5'>
                            <img src={newLogo} alt="" />
                            <span className='header-logo-text'>QR Code Developer</span>
                        </div>
                        <div className='footer-main-right'>
                            <div className='footer-main-right-links'>
                                <Link className='footer-right-link' to={generateUrl("/terms-of-service")}>
                                    {t("termsAndConditions")}
                                </Link>
                                <Link className='footer-right-link' to={generateUrl("/privacy-policy")}>
                                    {t("privacyPolicy")}
                                </Link>
                                <Link className='footer-right-link' to={generateUrl("/contact-us")}>
                                    {t("contactUs")}
                                </Link>
                                <div onClick={handleNavigateToGuides} className='footer-right-link cursor-pointer'>
                                    {t("guides")}
                                </div>
                            </div>
                            <div className='footer-main-right-auth-buttons'>
                                <button onClick={handleLogin} className='footer-login-button'>{t("login")}</button>
                                <button onClick={handleSignUp} className='footer-create-account-button'>{t("createAnAccount")}</button>
                            </div>
                        </div>
                    </div>
                    <p className='footer-copyright'>
                        {t("copyright")} © {new Date().getFullYear()} | QR Code Developer | {t("allRightsReserved")}
                    </p>
                </div>
            </footer>
        </main>
    )
}

export default NewHome